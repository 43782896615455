import Dropzone from 'dropzone'

Dropzone.options.bulkEventsDropzone = {
  init: function() {
    this.on('success', function(file, resp) {
      let html = `Dominio: <strong>${resp.ident}</strong>, Nuevo evento creado: <a href=\"${resp.url}\" target=\"_blank\">${resp.event_id}</a>`
      $(file.previewTemplate).find('[data-dz-errormessage]').html(html)
    })
  },
  maxFilesize: 10,
  createImageThumbnails: false,
  dictDefaultMessage: "Arrastrar y soltar los archivos aquí.",
  dictFileTooBig: "Archivo demasiado grande ({{filesize}}MB). Tamaño máximo {{maxFilesize}}MB.",
  dictMaxFilesExceeded: "No se pueden subir más archivos.",
  previewTemplate: `
    <div class="dz-preview dz-file-preview">
    <div class="dz-details">
      <div class="dz-size"><span data-dz-size></span></div>
      <div class="dz-filename"><span data-dz-name></span></div>
    </div>
    <div class="dz-error-message"><span data-dz-errormessage></span></div>
    <div class="dz-success-mark">✔</div>
    <div class="dz-error-mark">✘</div>
    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
    </div>
  `
}
